@use '@angular/material' as mat;
@import 'variables';
@import '~leaflet/dist/leaflet.css';
@import 'styles-app-loading.scss';

/* You can add global styles to this file, and also import other style files */

body {
  margin: 0;
}

/**
* GLOBAL
 */

.spacer {
  flex: 1 1 auto;
}

//https://stackoverflow.com/questions/2005954/center-a-positionfixed-element
.fixed-center {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mat-input-tree-pane {
  @include mat.elevation(4);
  display: flex;
  align-items: stretch;
  background: white;
  flex-direction: column;
  justify-content: flex-start; // WAS START

  // ALlow content to be scrolled with custom overlay custom scrolling strategy
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/**
SNACKBAR
 */

.snackbar-info {
  //color: green !important;
}

.snackbar-alert {
  background-color: mat.get-color-from-palette($echo-app-accent) !important;
  .mat-button {
    color: white;
  }
}

/**
Custom mat-tab overriding style for FloorComponent
 */

echo-floor, echo-map {
  .mat-tab-header {
    //background: red;
  }
  .mat-tab-label {
    min-width: 25px !important; // In conjunction with mat-strech-tabs allowing tabs to fill remaining width of parent
    padding: 5px;
  }
}

echo-floor-selection-tab {
  .mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(to right,rgba(0,0,0,1) 0,rgba(0,0,0,.42) 33%,transparent 0);
    background-size: 4px 100%;
    background-repeat: repeat-x;
  }
}

/**
Fix for large radio buttons label
https://github.com/angular/components/issues/10954
 */
.mat-radio-label {
  white-space: normal !important;
}

.grey-icon {
  color: rgba(0, 0, 0, 0.54);
}

// POC
// Changement de curseur sur le svg
// Doit nécessairement être placé dans le global.css
.svg-wall-inserting {
  cursor: url('./assets/img/cursor-reticle.svg') 8 8, grab !important;
}
