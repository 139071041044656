.app-loading {
  .logo {
    width: 100px;
    height: 100px;
    // this way asset gets processed by webpack
    background: url("./assets/img/ecsy-logo@0,75x.png") center center no-repeat;
    background-size: contain;
  }
  .slow-fade-in {
    animation: fadeIn 6s infinite ease;
  }
  .fast-fade-in {
    animation: fadeIn 1s infinite ease;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.25;
  }
  to {
    opacity: 1;
  }
}
